import React, { useEffect, useState } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import { Config } from "../../Utils/AppConstants";

import "./SubscriptionManagement.css"; // Import your CSS file for animations

import { useNavigate } from "react-router-dom";
import { useFintechLayouts} from "../../services/AsaApiServices"

import {useIsFetching, useIsMutating } from "react-query";
import LoaderComponent from "../../components/LoaderComponent";
import bars_home from "../../assets/Images/bars_home.svg";

const SubscriptionManagementBase = ({ children, ...props }:{children:React.ReactNode,[props:string]:any}) => {

  const navigate = useNavigate();
  const fintechLaoyut=useFintechLayouts() //getLayoutFromFintech(fintech);
  const fintechIcon =fintechLaoyut?.icon ?? ""; // Fallback for icon
  const appName=fintechLaoyut?.appName ?? "Guac"
  
  const isFetching = useIsFetching()
  const isMutating =useIsMutating()
  // Handling Update Session Error
  const isLoading=isFetching>0 || isMutating>0 

  return (
    <div className="flex justify-center items-center overflo h-screen">
      {isLoading && <LoaderComponent />}
      <div
        className="w-full h-full flex flex-col bg-gradient-guac text-white relative z-20"
        style= {fintechLaoyut?.backGroundStyle }
      >
        <div className="flex items-center justify-between p-4 "
        >
          <span className="back-arrow"
           style= {fintechLaoyut?.buttonStyle }
           onClick={()=>navigate(-1)}
          >
            <img
              alt="back"
              className="cursor-pointer p-2 shadow-2xl rounded-lg w-7 h-7"
              src={arrow_back_ios_new}
            />
          </span>
        </div>

        <div
          className={`flex-grow relative rounded-t-3xl mt-6 p-4 flex flex-col ${
            Config.isLight ? "bg-[#EBECF0]" : "bg-[#121212]"
          }`}
        >
          
          <div className="relative mb-2">
            <img
              alt="Guac"
              src={fintechIcon}
              className="absolute -top-10 opacity-90 left-0 z-50 w-20 h-15 imgicon"
            />

            <span className="ml-5 custom-span font-poppins absolute left-20 -top-2 text-[#FFFFFF]">
              {appName}
            </span>
          </div>
          {children}
         <div className="flex flex-col flex-grow items-center">
                <img src={bars_home} className="flex kidpay-barshome-img" alt="____"/>
          </div>   
        </div>
      </div>
    </div>
  );
};

export default SubscriptionManagementBase;
