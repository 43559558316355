import React,{ useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


import "./CancelSubscriptionScreen.css"; // Import your CSS file for animations

import {useSubscriptionCancedlMutation,useFintechLayouts,useFintechSubscriptions,usePaymentMethods} from "../../services/AsaApiServices"
import SubscriptionManagementLogo from "./SubscriptionManagementLogo"
import { useIsFetching,useIsMutating } from "react-query";

import SubscriptionManagementBase from "./SubscriptionManagementBase"

import visa from "../../assets/Images/visa.png";
import city_double_cash from "../../assets/Images/city_double_cash.png";
import check_everyday from "../../assets/Images/check_everyday.png";

import pwa_image from "../../assets/Images/pwa_image.png";

const getImageSource = (type: string | undefined) => {
  switch (type) {
    case "ACH":
      return check_everyday;
    case "Bank":
      return check_everyday; // Assuming Bank uses the same image as check_everyday
    case "Visa":
      return visa;
    case "CC":
      return city_double_cash;
    default:
      return pwa_image;
  }
};


const ChangePaymentMethodScreen = () => {
  const navigate = useNavigate();


  const fintechSubscriptions=useFintechSubscriptions()
  const fintechLaoyut=useFintechLayouts() //getLayoutFromFintech(fintech);

  const subscriptionCancelMutation= useSubscriptionCancedlMutation()
  const activeSubscription=Array.isArray(fintechSubscriptions?.data)?fintechSubscriptions.data[0]:undefined
  const {data:paymMethods}=usePaymentMethods()
  const [paymMethod,setPaymMethod] =useState<PaymentMethod | undefined>({})


   useEffect(() => {
   if(activeSubscription && paymMethods){
      //const selected=paymMethods.find((x)=> activeSubscription.)
   }
  
   }, [activeSubscription,paymMethods]);
  console.log(activeSubscription)
  const handleCancel = async () => {
    const billingDetailCode = activeSubscription?.billingDetailCode;

    if (!billingDetailCode) {
      console.error("BillingDetailCode is missing");
      return; // Exit if no billing detail code is available
    }

    const requestData = {
      BillingDetailCode: billingDetailCode,
    };

    try {
      await subscriptionCancelMutation.mutateAsync(requestData)
      navigate("/cancelled");
    } catch (error) {
      console.error( error);
     
    }
  };

  return (
    <SubscriptionManagementBase>
        <div className="flex flex-col items-center justify-center flex-grow">
            <div className="cancel-trial-title"> 
              Change Payment Method

            </div>
            <div className="relative p-4 rounded-full">
              <div className="flex items-center justify-center">
                <div className="relative p-4 rounded-full">
                  <SubscriptionManagementLogo layout={fintechLaoyut}/>

                </div>
              </div>
            </div>
            <div className="flex flex-col justify-between">
            {paymMethods?.map((p)=>
                
           
                 <span className="flex flex-row justify-between space-x-2 mt-2 mb-2">
                   <span className="guac-gold-text mr-2">
                    {p.paymentMethodName}
                   </span>
                   <span className="bg-white text-white p-1 rounded-md text-xs">
                     <img
                       src={getImageSource(activeSubscription?.paymentType )}
                       alt={activeSubscription?.paymentType ||  "Payment Method" }
                       className="w-5 h-5 rounded m"
                       style={{ objectFit: "contain" }}
 
                     />
                   </span>
                 </span>
              
              )

            }
            </div>
        </div>
        
          <div className="flex justify-between px-6 py-4 space-x-4">
            <button
              className="manage-trial-btn-cancel"
              style= {fintechLaoyut?.buttonStyle }
              onClick={()=>navigate(-1)}
              
            >
              GO BACK
            </button>
            <button
              className="cancel-trial-btn-txt-trial"
              onClick={handleCancel} // Add the click handler
            >
               {"Cancel"+(activeSubscription?.inTrial?" Trial":"")}
              
            </button>
          </div>
    </SubscriptionManagementBase>

 
  );
};

export default ChangePaymentMethodScreen;
