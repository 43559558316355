import {IFintechLayout} from "../../types/types"
const defaultColor="#1b6cb5"
const hexToRgb = (hex: string) => {
    // Remove the leading # if it's there
    hex = hex.replace(/^#/, "");

    // Parse the r, g, b values
    const bigint = parseInt(hex, 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;

    // Normalize the RGB values to be between 0 and 1 (feColorMatrix expects normalized values)
    return { r: r / 255, g: g / 255, b: b / 255 };
  };

 interface ISubscriptionManagementLogoProps {
    layout:IFintechLayout | null
    
  }
const  SubscriptionManagementLogo:React.FC<ISubscriptionManagementLogoProps> = ({layout}) => 
{
    const gradientRgb = hexToRgb(layout?.gradientColorStart??defaultColor);
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width="186"
        height="186"
        viewBox="0 0 186 186"
        fill="none"
        >
        <g filter="url(#filter0_d_16343_1495)">
        <circle cx="93" cy="89" r="58" fill="#121212" />
        <circle
            cx="93"
            cy="89"
            r="56.5"
            stroke={layout?.gradientColorStart??defaultColor}
            strokeWidth="3"
        />
        {/* Nested SVG */}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="72"
            height="72"
            x="57" // Adjust the x position (186 / 2 - 72 / 2 = 57)
            y="47" // Adjust the y position (186 / 2 - 72 / 2 = 47)
            viewBox="0 0 72 72"
            fill="none"
        >
            <g filter="url(#filter0_i_16370_1570)">
            <mask
                id="mask0_16370_1570"
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="72"
                height="72"
            >
                <rect width="72" height="72" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_16370_1570)">
                <path
                d="M54.8363 50.4285V43.4422C54.8363 43.0807 54.7055 42.7692 54.444 42.5077C54.1825 42.2462 53.871 42.1155 53.5095 42.1155C53.148 42.1155 52.8365 42.2462 52.575 42.5077C52.3135 42.7692 52.1828 43.0807 52.1828 43.4422V50.3827C52.1828 50.7442 52.248 51.0885 52.3785 51.4155C52.5095 51.7425 52.7152 52.0462 52.9958 52.3268L57.6862 57.0173C57.9478 57.2787 58.2545 57.4143 58.6065 57.4238C58.9585 57.4338 59.275 57.2982 59.556 57.0173C59.8365 56.7367 59.9768 56.4253 59.9768 56.0828C59.9768 55.7403 59.8365 55.4288 59.556 55.1483L54.8363 50.4285ZM53.556 64.9328C49.6445 64.9328 46.327 63.5837 43.6035 60.8857C40.8805 58.1878 39.519 54.883 39.519 50.9715C39.519 47.06 40.8805 43.7503 43.6035 41.0423C46.327 38.3348 49.6445 36.981 53.556 36.981C57.4365 36.981 60.7258 38.3425 63.4238 41.0655C66.1217 43.7885 67.4707 47.0905 67.4707 50.9715C67.4707 54.852 66.1217 58.149 63.4238 60.8625C60.7258 63.576 57.4365 64.9328 53.556 64.9328ZM12.9233 58.5C11.4077 58.5 10.125 57.975 9.075 56.925C8.025 55.875 7.5 54.5923 7.5 53.0768V18.9233C7.5 17.4077 8.025 16.125 9.075 15.075C10.125 14.025 11.4077 13.5 12.9233 13.5H59.0768C60.5923 13.5 61.875 14.025 62.925 15.075C63.975 16.125 64.5 17.4077 64.5 18.9233V30.5422C64.5 31.1807 64.2845 31.7155 63.8535 32.1465C63.423 32.577 62.8885 32.7922 62.25 32.7922C61.6115 32.7922 61.077 32.577 60.6465 32.1465C60.2155 31.7155 60 31.1807 60 30.5422V25.212H12V34.788H36.7215C37.456 34.788 37.9753 35.14 38.2793 35.844C38.5828 36.548 38.4693 37.1885 37.9388 37.7655C36.3193 39.454 35.046 41.427 34.119 43.6845C33.192 45.942 32.7285 48.342 32.7285 50.8845C32.7285 51.6615 32.772 52.426 32.859 53.178C32.9455 53.93 33.0847 54.654 33.2767 55.35C33.4692 56.142 33.327 56.865 32.85 57.519C32.373 58.173 31.748 58.5 30.975 58.5H12.9233Z"
                fill={layout?.gradientColorStart??defaultColor}
                />
            </g>
            </g>
            <defs>
            <filter
                id="filter0_i_16370_1570"
                x="7.5"
                y="13.5"
                width="59.9707"
                height="55.4336"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
            >
                <feFlood
                floodOpacity="0"
                result="BackgroundImageFix"
                />
                <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
                />
                <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
                />
                <feOffset dy="4" />
                <feGaussianBlur stdDeviation="2" />
                <feComposite
                in2="hardAlpha"
                operator="arithmetic"
                k2="-1"
                k3="1"
                />
                <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                />
                <feBlend
                mode="normal"
                in2="shape"
                result="effect1_innerShadow_16370_1570"
                />
            </filter>
            </defs>
        </svg>
        </g>
        <defs>
        <filter
            id="filter0_d_16343_1495"
            x="0"
            y="0"
            width="186"
            height="186"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
        >
            <feFlood
            floodOpacity="0"
            result="BackgroundImageFix"
            />
            <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
            />
            <feMorphology
            radius="5"
            operator="dilate"
            in="SourceAlpha"
            result="effect1_dropShadow_16343_1495"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="15" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
            type="matrix"
            values={`0 0 0 0 ${gradientRgb.r} 0 0 0 0 ${gradientRgb.g} 0 0 0 0 ${gradientRgb.b} 0 0 0 1 0`}
            />
            <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_16343_1495"
            />
            <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_16343_1495"
            result="shape"
            />
        </filter>
        </defs>
        </svg>
    )
}

export default SubscriptionManagementLogo