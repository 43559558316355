import React, { useEffect, useState, useCallback } from "react";
import arrow_back_ios_new from "../../assets/Images/arrow_back_ios_new.png";
import pwa_image from "../../assets/Images/pwa_image.png";
import visa from "../../assets/Images/visa.png";
import city_double_cash from "../../assets/Images/city_double_cash.png";
import check_everyday from "../../assets/Images/check_everyday.png";
import dropdown_arrow from "../../assets/Images/arrow_drop_down.png";
import arrow_open from "../../assets/Images/arrow_drop_down_portfolio.svg"; // Assuming it's the same as dropdown_arrow
import dropdown_arrow_close from "../../assets/Images/uo_arrow.png";
import more_vert from "../../assets/Images/more_vert_portfolio.svg";
import edit_icon from "../../assets/Images/edit.png";
import delete_icon from "../../assets/Images/delete.png";
import "./SubscriptionPayment.css"; // Plan NameImport your CSS file for animations
import add_icon from "../../assets/Images/add.png";
import { ToastContentProps, toast } from "react-toastify";
import { useQueryClient ,useIsFetching,useIsMutating} from "react-query";
import close_icon from "../../assets/Images/close_small_portfolio.svg";
import { useNavigate } from "react-router-dom";
import { makeApiCall } from "../../services/ApiService";

import {useAsaSession,useAsaSessionPagesMutation} from '../../services/AsaSessionServices'
import {useActiveSubscriptions,useSubscriptionsPlans,
  useSubscriptionPurchaseMutation,useFintechLayouts,useBillingAddresses,
  useSubscriptionPaymentMutation,usePaymentMethods,
  useBillingAddresseslMutation,useSubscriptionPaymentMethodUpdateMutation} from '../../services/AsaApiServices'

import {
  API_CALL_TYPE,
  
  POST_PAYMENT_METHOD,

} from "../../services/Api";
import ConfirmDialog from "../../components/ConfirmDialog ";
import { useDispatch, useSelector } from "react-redux";
import {
  SessionState,
  setNewPaymentField,
  setPaymentCVV,
} from "../../redux/sessionSlice";

import {
  BillingAddress,
  FilteredSubscriptions,
  PaymentMethod,
  Plan,
  SessionData,
  SessionUpdateRequest,
  SubscriptionDetail,
  SubscriptionPlan,
  SubscriptionPlanData,
} from "../../types/types";
import { Config } from "../../Utils/AppConstants";
import ConfirmDialogCVV from "../../components/ConfirmDialogCVV";
import LoaderComponent from "../../components/LoaderComponent";



const SubscriptionPayment: React.FC = () => {

  const {data:billingAddresses}=useBillingAddresses()
  const billinAddressMutation=useBillingAddresseslMutation()
  const {data:paymentMethods}= usePaymentMethods()
  const layout=useFintechLayouts()
  const {data:session}=useAsaSession()
  const sessionMutation=useAsaSessionPagesMutation()
  const {data:activesubscriptions}=useActiveSubscriptions()
  const subscriptionPaymentMutation= useSubscriptionPaymentMutation();
  const subscriptionPaymentMethodMutation=useSubscriptionPaymentMethodUpdateMutation()
  const isFetching = useIsFetching()
  const isMutating =useIsMutating()
  const isQueryLoading=isFetching>0 || isMutating>0
  const [selectedPaymentMethod,setSelectedPaymentMethod] = useState<PaymentMethod | undefined>()
  const [selectedBillingAddr,setSelectedBillingAddr] = useState<BillingAddress | undefined>()

  //---------------
  const fintechName=""
  const [showbillAddress, setShowPBillAddress] = useState(false);
  const [selectedPaymentIndex, setSelectedPaymentIndex] = useState<
    number | null
  >(null);
  const navigate = useNavigate();
  
  const [isLanding, setisLanding] = useState(false);
  const [cvv, setCVV] = useState("");
  const [showOptions, setShowOptions] = useState(true);
  const [subscriptionPlanData, setSubscriptionPlanData] =
    useState<SubscriptionPlan | null>(null);
  const [activePlanData, setActivePlanData] =
    useState<SubscriptionPlanData | null>(null);
  const [previousPlanData, setPreviousPlanData] =
    useState<SubscriptionPlanData | null>(null);
  const [filteredPlansData, setFilteredPlansData] =
    useState<FilteredSubscriptions | null>(null);

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [moreVertOpen, setMoreVertOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false); // State for loading indicator
  const [isBillingDropdownOpen, setIsBillingDropdownOpen] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showDialogCVV, setShowDialogCVV] = useState(false);
  const [showConfirmDialogPayment, setShowConfirmDialogPayment] =
    useState(false);
  const [deletePaymentIndex, setDeletePaymentIndex] = useState<number | null>(
    null
  );
  const [visibleOptionsIndex, setVisibleOptionsIndex] = useState<number | null>(
    null
  );
  const dispatch = useDispatch();

  const [deletePaymentIndexCard, setDeletePaymentIndexCard] = useState<
    number | null
  >(null);

  const paymentCVVs = useSelector(
    (state: SessionState) => state.session.paymentCVVs
  );

  function IsSubscriptionUpgrade():boolean{
    //to dodo always false  because method not works
    return false;
    if(!activeSubscription()){
      return false;
    }
    
    return activeSubscription()?.subscriptionPlanCode!==subscriptionPlanData?.pageData.subscriptionPlanCode;
  }

  function activeSubscription():SubscriptionDetail | undefined{
    return activesubscriptions?activesubscriptions[0]:undefined
  }




  const handleCVVChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    paymentMethodId: number
  ) => {
    const newCVV = e.target.value;
    // Ensure that CVV is not logged or displayed inappropriately
    setCVV(newCVV);
    dispatch(setNewPaymentField(newCVV)); // Only use newCVV here

    // Update CVV in Redux store
    dispatch(setPaymentCVV({ paymentMethodId, cvv: newCVV }));

    if (newCVV.length === 4) {
      setIsDropdownOpen(false);
    }
  };

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };




  useEffect(() => {


    const sessionPages = session?.sessionPages
    if (!sessionPages || sessionPages.length === 0) {

      return;
    }
    sessionPages.forEach((page: { pageName: any; pageData: string }) => {
      try {
        switch (page.pageName) {
          case "GOALS PLANS": {
            const parsedPageData = JSON.parse(page.pageData.replace(/\\/g, ""));
            if (parsedPageData && !Array.isArray(parsedPageData)) {
              setSubscriptionPlanData({
                pageName: page.pageName,
                pageData: parsedPageData,
              });

              if (parsedPageData && parsedPageData.length > 0) {
                // setFintechName(parsedPageData[0].fintechName);

                Config.asaFintechCode = parsedPageData[0].asaFintechCode;
              }
            }
           
            break;
          }

          case "Current Plan": {
            const currentPlans = JSON.parse(page.pageData.replace(/\\/g, ""));
            setActivePlanData(currentPlans);
            break;
          }

          case "Landingyes": {
            const parsedPageData = JSON.parse(page.pageData.replace(/\\/g, ""));
            setisLanding(parsedPageData.Landing);
            break;
          }
          default:
            break;
        }
      } catch (error) {
        console.error(`Error processing page '${page.pageName}':`, error);
      }
    });


  }, [session]);
  //set default payment method
  useEffect(()=>{
   if(paymentMethods && paymentMethods?.length>0 && session ){
      const saved=session.sessionPages.find(x=>x.pageName==="SelectedPaymentMethod")
      
      const savedPayMethod=JSON.parse(saved?.pageData??"")
      const matched=paymentMethods.find(x=>x.paymentMethodId===savedPayMethod?.paymentMethodId)

      setSelectedPaymentMethod(matched??paymentMethods[0])
   }
  },[paymentMethods,session])
  useEffect(()=>{
    if(billingAddresses && billingAddresses?.length>0){
       setSelectedBillingAddr(billingAddresses[0])
    }
     
   },[billingAddresses])

  const saveUserSelection=async (saveOption:{billingAddr:BillingAddress | undefined | null,payMethod:PaymentMethod| undefined | null})=>{
    try {
      if(!saveOption.billingAddr && !saveOption.payMethod){
        return;
      }
      const sessionToUpdate:IAsaSession ={...session}
      sessionToUpdate.sessionPages=undefined
      const sessionpages=[]
      if(saveOption.payMethod){
        const userSelectionPage:SessionPage={
          pageNumber:5,
          pageName: "SelectedPaymentMethod",
          pageTitle: "Select Package",
          pageDescription: "Select Package",
          pageUrl: "/moneypatrol",
          pageStatusMessage: "INCOMPLETE",
          pageData:JSON.stringify(saveOption.payMethod)
        }
        sessionpages.push(userSelectionPage)
      }
      if(saveOption.billingAddr){
        const userSelectionPage:SessionPage={
          pageNumber:25,
          pageName: "SelectedBillingAddress",
          pageTitle: "Select Package",
          pageDescription: "Select Package",
          pageUrl: "/moneypatrol",
          pageStatusMessage: "INCOMPLETE",
          pageData:JSON.stringify(saveOption.billingAddr)
        }
        sessionpages.push(userSelectionPage)
      }
      const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:sessionpages})
      
    } catch (error) {
      console.error("Error saving session:", error);
    }
  }

  const handleDeletePaymentMethod = async (index: number) => {
    setDeletePaymentIndex(index);
    setShowConfirmDialog(true);
  };

  const handleDeletePaymentMethodCards = async (index: number) => {
    setShowConfirmDialogPayment(true);
    setDeletePaymentIndexCard(index);
  };


  const handleReviewAndPayClick = () => {
    // Handle click action, such as navigating to another screen or performing some other action
    if (isLanding) {

        const sessionPages = session?.sessionPages || []
        let pageUrlToNavigate = null;

        for (let i = 0; i < sessionPages.length; i++) {
          if (sessionPages[i].pageNumber === 81) {
            // Retrieve the page URL for page number 81
            pageUrlToNavigate = sessionPages[i].pageUrl;
            break;
          }
        }

        if (pageUrlToNavigate) {
          // Navigate to the URL for page number 81
          navigate(pageUrlToNavigate);
        } else {
          console.error("Page URL for page number 81 not found.");
        }
      
    }
  };
  
  const handleConfirmDelete = async () => {
    if (deletePaymentIndex !== null) {
      try {
        const billingAddressToDelete = billingAddresses[deletePaymentIndex];
       
        const requestData = [
          {
            consumerAddressId: billingAddressToDelete.consumerAddressId,
            address1: billingAddressToDelete.address1,
            city: billingAddressToDelete.city,
            state: billingAddressToDelete.state,
            zipCode: billingAddressToDelete.zipCode,
            firstName: billingAddressToDelete.firstName,
            lastName: billingAddressToDelete.lastName,
            country: billingAddressToDelete.country,
            isCurrent: false,
            isActive: false,
            moveoutDate: null,
            isBilling: true,
            isPrimary: false,
            isShipping: false,
            addressType: null,
          },
        ];

        try {
            var res=await billinAddressMutation.mutateAsync(requestData)
        } catch (error) {
            console.log(error)
        } finally {
          // Set loading state to false after API call is completed (whether success or error)
        }

       
        setDeletePaymentIndex(null);
        setShowConfirmDialog(false);
      } catch (error) {
        console.error("Error deleting billing address:", error);
      }
    }
  };

  const handleDeletePaymentResponse = async (response: any) => {
    // Handle success response
    setIsLoading(false);
    showCustomToast(response.message, false);
    // navigate("/PortfolioPayment");
  };

  const handlePaymentMethodError = (error: any) => {
    setIsLoading(false);
    showCustomToast(error.message, true);
  };

  const handleConfirmDeletePaymet = async () => {
    if (deletePaymentIndexCard !== null) {
      try {
        try {
          // Prepare the data for deletion
          const paymentMethodToDelete = paymentMethods[deletePaymentIndexCard];
          // Make API call to delete payment method with headers
          makeApiCall(
            API_CALL_TYPE.DELETE_CALL, // Using DELETE method
            POST_PAYMENT_METHOD(), // Endpoint URL for DELETE
            handleDeletePaymentResponse, // Success callback
            handlePaymentMethodError, // Error callback
            null, // formData (not used in this case)
            Config.token, // token (not used in this case)
            {
              asaPaymentMethodCode: paymentMethodToDelete?.asaPaymentMethodCode,
            } // Body for DELETE
          );
          const updatedBillingAddresses = [...paymentMethods];
          updatedBillingAddresses.splice(deletePaymentIndexCard, 1);
          //setPaymentMethods(updatedBillingAddresses);
          setDeletePaymentIndexCard(null);
          setShowConfirmDialogPayment(false);
        } catch (error) {
          // Handle error scenarios
        }
      } catch (error) {
        console.error("Error deleting payment Address address:", error);
        // Handle error scenarios
      }
    }
  };

  const handleEditPaymentMethod = (index: number) => {
    const paymentMethodsIn = paymentMethods[index];
    navigate("/PaymentOneGoal", { state: { payment: paymentMethodsIn } });
  };

  const handleEditBillingAddress = (address: BillingAddress) => {
    navigate("/AddressPortfolio", { state: { address: address } });
  };

  const togglePaymentMethods = () => {
    // Toggle both `isDropdownOpen` and `showPaymentMethods` together
    const newDropdownState = !isDropdownOpen;
    setIsDropdownOpen(newDropdownState);
  };

  const onAddPaymentMethod = () => {
    navigate("/AddMethodsPortfolio");
  };

  const toggleBillingAddress = () => {
    setIsBillingDropdownOpen(!isBillingDropdownOpen);
    setShowPBillAddress(!showbillAddress);
  };


  const addBilling = () => {
    navigate("/AddressPortfolio");
  };

  const getImageSource = (type: string) => {
    switch (type) {
      case "ACH":
        return check_everyday;
      case "Bank":
        return check_everyday; // Assuming Bank uses the same image as check_everyday
      case "Visa":
        return visa;
      case "CC":
        return city_double_cash;
      default:
        return pwa_image;
    }
  };

  const handlePaymentSelection = async (method: PaymentMethod) => {
   
  
    if (method.paymentType === "CC") {
      setIsDropdownOpen(true);
    } 
    if (method.paymentType === "ACH") {
      setIsDropdownOpen(false);
    } else {
      
     
      const cvv = paymentCVVs[method.paymentMethodId] || "";
      setCVV(cvv);
    }
   
    setSelectedPaymentMethod(method);
    await saveUserSelection({payMethod:method});
  
  };

  const handleCVVBlur = () => {
    if (cvv.length === 3) {
      // Proceed with your next steps if needed
    }
  };

  const handleCVVClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  const handleBackClick = () => {
    if (isLanding) {

        const sessionPages = session.sessionPages;
        let pageUrlToNavigate = null;

        for (let i = 0; i < sessionPages.length; i++) {
          if (sessionPages[i].pageNumber === 81) {
            // Retrieve the page URL for page number 81
            pageUrlToNavigate = sessionPages[i].pageUrl;
            break;
          }
        }

        if (pageUrlToNavigate) {
          // Navigate to the URL for page number 81
          navigate(pageUrlToNavigate);
        } else {
          console.error("Page URL for page number 81 not found.");
        }
      
    } else {

        const sessionPages = session.sessionPages;
        let backActionDynamicLink = null;

        for (let i = 0; i < sessionPages.length; i++) {
          if (sessionPages[i].pageNumber === 0) {
            const pageData = JSON.parse(sessionPages[i].pageData);
            backActionDynamicLink = pageData.backActionDynamicLink;
            break;
          }
        }
        if (backActionDynamicLink) {
          window.location.href = backActionDynamicLink;
        } else {
          console.error("Back action dynamic link not found.");
      }
    }
  };

 
  const handleAddressSelection = async (
    address: BillingAddress
  ) => {
    
    // Toggle options if the same item is clicked again
    if (selectedBillingAddr == address) {
      setShowOptions(!showOptions); // Assuming you want to toggle options visibility
    } else {
      setSelectedBillingAddr(address); // Set the new selected address index
      setShowOptions(true); // Ensure options are shown for the new selection
    }
    // Toggle the dropdown visibility
    setIsBillingDropdownOpen(!isBillingDropdownOpen);
    saveUserSelection({billingAddr:address})
   
  };

  
  const handleConfirmCVV = (enteredCVV: string) => {
    setCVV(enteredCVV);
    setShowDialogCVV(false);
    handlePaymentProcess(
      selectedBillingAddr,
      selectedPaymentMethod,
      enteredCVV
    );
  };


  const handleapp = async (billingfromstore: any, paymentfromstore: any) => {
    if (paymentfromstore === null) {
      return; // or handle the error appropriately
    }
    handlePaymentProcess(selectedBillingAddr, selectedPaymentMethod, "");
  };

  const handleUpdate = async (billingfromstore: any, paymentfromstore: any) => {
    if (paymentfromstore === null) {
      return; // or handle the error appropriately
    }
    handleUpdatePaymentMethodProcess(
      selectedBillingAddr,
      selectedPaymentMethod,
      ""
    );
  };

  // Function to calculate prorated amount (implement actual logic based on your needs)
  function calculateProratedAmount(previousAmount: number, newAmount: number) {
    // Simplified example for prorated amount calculation
    // Replace with actual calculation logic as needed
    return newAmount - previousAmount;
  }

  const handlePaymentProcess = async (
    billingfromStore: any,
    paymentfromStore: any,
    enteredCVV?: string
  ) => {
    // Check CVV only if payment type is 'cc'

    // Get the CVV corresponding to the selected payment method
    const selectedPaymentMethodId = paymentfromStore?.paymentMethodId;
    const cvvToUse = enteredCVV || paymentCVVs[selectedPaymentMethodId] || cvv;

    if (
      paymentfromStore?.paymentType === "cc" &&
      (!/^\d+$/.test(cvv) || !(cvv.length === 3 || cvv.length === 4))
    ) {
  
      showCustomToast(" Error in CVV", true);
      return;
    }
    let billingAmount;
    // Check if `filteredPlansData` exists and `inTrial` is `false`
    if (filteredPlansData && filteredPlansData[0]?.inTrial === false) {
      // Proceed with plan upgrade/downgrade logic
      if (activePlanData && previousPlanData) {
        if (activePlanData.recurringAmount > previousPlanData.recurringAmount) {
          // Plan upgraded, calculate prorated amount
          billingAmount = calculateProratedAmount(
            previousPlanData.recurringAmount,
            activePlanData.recurringAmount
          );
        } else if (
          activePlanData.recurringAmount < previousPlanData.recurringAmount
        ) {
          // Plan downgraded, use the one-time amount or other logic
          billingAmount = activePlanData.recurringAmount; // Or any other logic for downgrade
        } else {
          // No change in plan
          billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
        }
      } else {
        billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
      }
    } else {
      // If `inTrial` is true or `filteredPlansData` is not present, use the one-time amount
      billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
    }


    const requestBody = {
      products: [""],
      billingDetailRequestModel: {
        billingAmount: billingAmount,
        comment: `Payment for Fintech ${fintechName}`,
        isRecurring: subscriptionPlanData?.pageData.isRecurring,
        RecurringAmount: subscriptionPlanData?.pageData.recurringAmount,
        isSubscription: true,
        consumerSignature: null,
        autoPay: true,
        recurringStartDate: null,
        billingEmail: null,
        paymentMethodId: paymentfromStore?.paymentMethodId,
        paymentMethodCode: paymentfromStore?.asaPaymentMethodCode,
        BillingCycle: subscriptionPlanData?.pageData?.billingCycle,
        isAcceptTerms: true,
        paymentType: null,
        bankAccountNumber: null,
        bankRoutingNumber: null,
        bankName: null,
        cardType: null,
        cardNumber: null,
        expiryMonth: null,
        expiryYear: null,
        firstName: null,
        middleName: null,
        lastName: null,
        CardHolderName:
          paymentfromStore?.paymentType == "ACH"
            ? null
            : paymentfromStore?.cardHolderName,
        CVV: paymentfromStore?.paymentType == "ACH" ? null : cvvToUse,
        subscriptionPlanCode: activePlanData
          ? activePlanData?.subscriptionPlanCode
          : subscriptionPlanData?.pageData.subscriptionPlanCode,
        address: billingfromStore?.address1,
        address2: billingfromStore?.address2,
        city: billingfromStore?.city,
        state: billingfromStore?.state,
        country: billingfromStore?.country,
        postalCode: billingfromStore?.zipCode,
      },
    };
    try{
      var res=await subscriptionPaymentMutation.mutateAsync(requestBody)
      
      showCustomToast(res?.message, false);
      navigate("/portfolioGoal", { state: res?.message});
    }
    catch(err){
     
      showCustomToast(err, true);
    }
   
  };

  const handleUpdatePaymentMethodProcess = async (
    billingfromStore: any,
    paymentfromStore: any,
  
  ) => {

    // Check CVV only if payment type is 'cc'

    // Get the CVV corresponding to the selected payment method
 
    let billingAmount;
    // Check if `filteredPlansData` exists and `inTrial` is `false`
    if (filteredPlansData && filteredPlansData[0]?.inTrial === false) {
      // Proceed with plan upgrade/downgrade logic
      if (activePlanData && previousPlanData) {
        if (activePlanData.recurringAmount > previousPlanData.recurringAmount) {
          // Plan upgraded, calculate prorated amount
          billingAmount = calculateProratedAmount(
            previousPlanData.recurringAmount,
            activePlanData.recurringAmount
          );
        } else if (
          activePlanData.recurringAmount < previousPlanData.recurringAmount
        ) {
          // Plan downgraded, use the one-time amount or other logic
          billingAmount = activePlanData.recurringAmount; // Or any other logic for downgrade
        } else {
          // No change in plan
          billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
        }
      } else {
        billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
      }
    } else {
      // If `inTrial` is true or `filteredPlansData` is not present, use the one-time amount
      billingAmount = subscriptionPlanData?.pageData.oneTimeAmount;
    }

    const requestData = {
      paymentMethodCode: selectedPaymentMethod?.asaPaymentMethodCode || 2077982375,
      billingDetailCode: activeSubscription()?.billingDetailCode, // Assuming this value is constant  subscriptionPlanCode: activePlanData?.subscriptionPlanCode || 15,
      address: billingfromStore?.address1 || "St. Francisco",
      address2: null,
      city: billingfromStore?.city || "Austin",
      state: billingfromStore?.state || "Arizona",
      country: billingfromStore?.country || "US",
      postalCode: billingfromStore?.zipCode || "23456",
      
    };
    try{
      const res=await subscriptionPaymentMethodMutation.mutateAsync(requestData)
         
      showCustomToast(res?.message, false);
      navigate("/portfolioGoal", { state: res?.message});
    }
    catch(err){
     
      showCustomToast(err, true);
    }
  };

  const handleMoreOptionsClick = (index: number) => {
    setVisibleOptionsIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCloseOptions = () => {
    setVisibleOptionsIndex(null);
  };

  const getPeriodCharge = () => {
    if (subscriptionPlanData) {
      if (subscriptionPlanData.pageData.oneTimeAmount != subscriptionPlanData.pageData.recurringAmount) {
        return "one-time"
      }
      return subscriptionPlanData.pageData?.billingCycle === "Annually" ? "/Year" : "/Month"
    }
    return "No subscription plan selected"
  }

  const getDisclosureText = () => {
    // TODO: - Need to add disclosure text to ASACentral Config.
    if (fintechName === "Money Patrol") {

      switch (subscriptionPlanData?.pageData.subscriptionTitle) {
        case "Lifetime Access":
          return {
              title: `+ yearly recurring payment of $${subscriptionPlanData.pageData.recurringAmount}`,
              descr: "starting in one year, cancel anytime. "
          };
        case "Annual Subscription":
          return {
            title: "Cancel anytime, no payment due today.",
            descr: `monthly payments start in ${subscriptionPlanData.pageData.freeTrialPeriod-1} days.`
        };
        default:
          return null;
      }

    }
    return null
  }
  const isTrilStart=subscriptionPlanData?.pageData?.isConsumerEligibleForTrial && subscriptionPlanData?.pageData?.freeTrialPeriod>0




  return (
    <div className="relative gradient-bg-listpayments bg-gradient-to-b from-[#705AE2] to-[#490C95] flex flex-col justify-between overflow-y-scroll py-10 text-gray-100 mx-auto space-y-5 h-screen"
    style= {layout?.backGroundStyle }
    >
      {(isLoading || isQueryLoading) && <LoaderComponent />}
      <div className="flex items-center justify-between">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="w-6 h-6 sm:w-8 sm:h-8 ml-5"
            src={arrow_back_ios_new}
            alt="Back"
            onClick={handleBackClick} // Updated onClick handler for the back button
          />
          <p className="custom-style-bugs">Checkout with ASA Pay®</p>
        </div>
      </div>
      <div className="absolute top-24 left-0 card-lay-portfolio right-0 bottom-0 rounded-t-3xl p-4 z-10 overflow-y-auto more-rounded">
        <div className="flex items-center justify-between mt-8 mx-2">
          <p className="payment-heading-list-portfolio font-poppins font-semibold">
            Select or add a payment method.
          </p>
          <div className="image-container">
            <img
              className="inner-image"
              src={dropdown_arrow}
              alt="Inner Dropdown Arrow"
            />
            <img
              className="dropdown-img cursor-pointer"
              src={isDropdownOpen ? arrow_open : dropdown_arrow_close}
              alt="Dropdown"
              onClick={togglePaymentMethods}
            />
          </div>
        </div>

        {fintechName == "PortfolioPilot" ? (
          <p className="mx-3 text-light font-poppins">
            Billing starts after 14 day trial.
          </p>
        ) : null}

        {!isDropdownOpen && selectedPaymentMethod && (
          <div className="space-y-2 mt-2">
            <div className="flex items-center justify-between p-2 rounded-lg">
              <div className="flex items-center space-x-2">
                <div className="bg-white rounded p-1">
                  <img
                    src={getImageSource(
                      selectedPaymentMethod.paymentType
                    )}
                    alt={
                      selectedPaymentMethod.paymentType ||
                      "Payment Method"
                    }
                    className="w-8 h-5 rounded"
                    style={{ objectFit: "contain" }}
                  />
                </div>
                <span className="mt-name-portfolio truncate text-[#FFFFFF]">
                  {selectedPaymentMethod.paymentMethodName}
                </span>
                <div className="font-poppins text-[#FFFFFF] font-semibold text-base leading-24">
                  ••••{" "}
                  {selectedPaymentMethod.paymentType === "CC"
                    ? selectedPaymentMethod?.last4DigitsOfCardNumber
                    : selectedPaymentMethod?.last4DigitsOfAccountNumber}
                </div>
              </div>
            </div>
          </div>
        )}
        {isDropdownOpen && (
          <div className="space-y-2 X-2 mt-2 max-h-40 overflow-y-auto">
            {isLoading ? null : (
              <div className="space-y-2 mt-2 max-h-40 overflow-y-auto">
                {paymentMethods.map((method, index) => {
                  const isSelected =
                    selectedPaymentMethod?.paymentMethodId ===
                    method?.paymentMethodId;
             
                  return (
                    <div key={index}>
                      <div
                        className={`flex items-center justify-between p-2 rounded-lg cursor-pointer mb-3`}
                        style={{
                          backgroundColor:
                            selectedPaymentIndex === index || isSelected
                              ? "#2c2c2c"
                              : "transparent",
                          boxShadow:
                            selectedPaymentIndex === index || isSelected
                              ? "0px 4px 4px rgba(0, 0, 0, 0.25)"
                              : "none",
                        }}
                        onClick={() =>
                          handlePaymentSelection(method)
                        }
                      >
                        <div className="flex items-center space-x-2">
                          <div className="form-radio-outer circle-icon-pportfolio">
                            <div
                              className={`circle-pport ${
                                selectedPaymentIndex === index || isSelected
                                  ? "circle-pport-large"
                                  : "circle-pport-small"
                              }`}
                            />
                          </div>

                          <div className="bg-white rounded p-1">
                            <img
                              src={getImageSource(method.paymentType)}
                              alt={method.paymentType}
                              className="w-8 h-5 rounded"
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                          <span className="mt-name-portfolio truncate-portfolio text-[#FFFFFF]">
                            {method?.paymentMethodName}
                          </span>

                          {(isDropdownOpen && selectedPaymentMethod ==method) ||
                          (method.paymentType === "CC" && isSelected) ? (
                            method.paymentType === "CC" ? (
                              <input
                                type="password"
                                placeholder="CVV"
                                value={
                                  paymentCVVs[method?.paymentMethodId] || ""
                                }
                                maxLength={4}
                                onChange={(e) =>
                                  handleCVVChange(e, method?.paymentMethodId)
                                }
                                onBlur={handleCVVBlur}
                                onClick={handleCVVClick}
                                inputMode="numeric"
                                style={{
                                  backgroundColor: "#616161",
                                  borderRadius: "10px",
                                }}
                                className="w-20 h-8 p-2 rounded font-poppins  text-[#F1F1F1] text-base leading-6 outline-none text-center box-border input-bg-gray"
                              />
                            ) : (
                              <div className="font-poppins text-[#F1F1F1] font-semibold text-base leading-24">
                                ••••{" "}
                                {method.paymentType === "CC"
                                  ? method?.last4DigitsOfCardNumber
                                  : method?.last4DigitsOfAccountNumber}
                              </div>
                            )
                          ) : (
                            <div className="font-poppins text-[#F1F1F1] font-semibold text-base leading-24">
                              ••••{" "}
                              {method.paymentType === "CC"
                                ? method?.last4DigitsOfCardNumber
                                : method?.last4DigitsOfAccountNumber}
                            </div>
                          )}
                        </div>

                        {selectedPaymentMethod ==method && (
                          <>
                            {moreVertOpen ? (
                              <div className="flex items-center space-x-2 ml-auto">
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => handleEditPaymentMethod(index)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={edit_icon}
                                    alt="Edit"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() =>
                                    handleDeletePaymentMethodCards(index)
                                  }
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={delete_icon}
                                    alt="Delete"
                                  />
                                </button>
                                <button
                                  className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                  onClick={() => setMoreVertOpen(false)}
                                >
                                  <img
                                    className="w-6 h-6 cursor-pointer"
                                    src={close_icon}
                                    alt="Close"
                                  />
                                </button>
                              </div>
                            ) : null}
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}

        {/* Add another payment method button */}
        {isDropdownOpen && (
          <button
            className="custom-button-addpayment mt-5 font-poppins mx-10 mb-5"
            onClick={onAddPaymentMethod}
          >
            <img
              src={add_icon}
              alt="Add"
              className="inline-block font-poppins mr-2 w-6 h-6"
            />
            <span className="custom-text-addpayment-portfolio font-semibold">
              Add another payment method
            </span>
          </button>
        )}
        <div className="divider-pportfolio"></div>
        {/* Selected payment index */}
        {selectedPaymentMethod 
          && selectedPaymentMethod?.paymentType === "CC" 
          && (
            <>
              {/* Billing address selection */}
              <div className="flex items-center justify-between mt-5 mx-2">
                <p className="payment-heading-list-portfolio font-poppins font-semibold">
                  Select or add billing address.
                </p>
                <div className="image-container">
                  <img
                    className="inner-image"
                    src={dropdown_arrow}
                    alt="Inner Dropdown Arrow"
                  />
                  <img
                    className="dropdown-img cursor-pointer"
                    src={
                      isBillingDropdownOpen ? arrow_open : dropdown_arrow_close
                    }
                    alt="Dropdown"
                    onClick={toggleBillingAddress}
                  />
                </div>
              </div>

              {/* Display selected billing address when dropdown is closed */}
              {!isBillingDropdownOpen &&
                selectedBillingAddr &&
                (
                  <div className="custom-box-pportfolio mt-3  rounded-lg p-2 flex items-center justify-between">
                    <div className="flex items-center space-x-2 p-2">
                      <span className="text-[#FFFFFF] ml-4 text-style-pportfolio">
                        {selectedBillingAddr?.firstName}{" "}
                        {selectedBillingAddr?.lastName},{" "}
                        {selectedBillingAddr?.address1}
                        <br />
                        <span className="text-light font-poppins font-semibold small-text-style-pportfolio">
                          {selectedBillingAddr.city}{" "}
                          {selectedBillingAddr.state},{" "}
                          {selectedBillingAddr.zipCode}, US
                        </span>
                      </span>
                    </div>
                  </div>
                )}

              {/* Billing address details when dropdown is open */}
              {isBillingDropdownOpen && billingAddresses.length > 0 && (
                <div className="mb-2 mt-2 max-h-30 overflow-y-auto">
                  {billingAddresses.map((address, index) => {
                    const isOptionsVisible = visibleOptionsIndex === index;
                    const isSelected =address==selectedBillingAddr
                    return (
                      <div
                        key={index}
                        className="bg-[#2C2C2C] custom-box-billing-pportfolio  mt-3  rounded-lg p-2 flex items-center justify-between"
                      >
                        <div className="flex items-center space-x-2 p-2">
                          <div
                            onClick={() =>
                              handleAddressSelection(address)
                            }
                            className="form-radio-outer circle-box-outer-pportfolio"
                          >
                            <div
                              style={{
                                width:
                                  isSelected
                                    ? "8px"
                                    : "0",
                                height:
                                isSelected
                                    ? "8px"
                                    : "0",
                                borderRadius: "50%",
                                backgroundColor: "#6B46C1",
                                transition: "width 0.2s, height 0.2s",
                              }}
                            />
                          </div>
                          <span
                            className="text-[#FFFFFF] ml-4 text-style-bold-pportfolio"
                            
                          >
                            {address.firstName} {address.lastName},{" "}
                            {address.address1}
                            <br />
                            <span className="text-[#FFFFFF] font-poppins font-semibold text-style-normal-pportfolio">
                              {address.city}, {address.state}, {address.zipCode}
                              ,{address?.country}.
                            </span>
                          </span>
                        </div>

                        <div className="flex items-center space-x-2">
                          {isOptionsVisible ? (
                            <>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={() => handleEditBillingAddress(address)}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={edit_icon}
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleDeletePaymentMethod(index);
                                }}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={delete_icon}
                                  alt="Delete"
                                />
                              </button>
                              <button
                                className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                                onClick={handleCloseOptions}
                              >
                                <img
                                  className="w-6 h-6 cursor-pointer"
                                  src={close_icon}
                                  alt="Close"
                                />
                              </button>
                            </>
                          ) : (
                            <button
                              className="text-gray-500 overflow-hidden whitespace-nowrap text-ellipsis"
                              onClick={() => handleMoreOptionsClick(index)}
                            >
                              <img
                                className="w-6 h-6 cursor-pointer"
                                src={more_vert}
                                alt="More Options"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {/* Add a billing address button */}
              {selectedPaymentMethod &&
                selectedPaymentMethod?.paymentType === "CC" &&
                isBillingDropdownOpen && (
                  <button
                    className="custom-button-addpayment mt-3 font-poppins mx-10 mb-5"
                    onClick={addBilling}
                  >
                    <img
                      src={add_icon}
                      alt="Add"
                      className="inline-block font-poppins mr-2 w-6 h-6"
                    />
                    <span className="custom-text-addpayment font-semibold">
                      Add a billing address
                    </span>
                  </button>
                )}

              <div className="divider-billdialog-pportfolio"></div>

              {showConfirmDialog && (
                <ConfirmDialog
                  title="Delete Billing Address"
                  message="Are you sure you want to delete this billing address?"
                  onCancel={() => {
                    setShowConfirmDialog(false);
                    setDeletePaymentIndex(null);
                  }}
                  onConfirm={() => {
                    // Handle confirm logic here

                    handleConfirmDelete();
                    setShowConfirmDialog(false); // Close dialog after action
                  }}
                  isOpen={showConfirmDialog}
                />
              )}
            </>
          )}
        {showDialogCVV && (
          <ConfirmDialogCVV
            title="ADd CVV"
            message="Please Enter Cvv"
            onCancel={() => setShowDialogCVV(false)}
            onConfirm={handleConfirmCVV} // Ensure this function is called on confirmation
            isOpen={showDialogCVV}
          />
        )}
        {showConfirmDialogPayment && (
          <ConfirmDialog
            title="Delete Payment Method"
            message="Are you sure you want to delete this payment method?"
            onCancel={() => setShowConfirmDialogPayment(false)}
            onConfirm={handleConfirmDeletePaymet} // Ensure this function is called on confirmation
            isOpen={showConfirmDialogPayment}
          />
        )}

        {subscriptionPlanData &&
          ((selectedPaymentMethod?.paymentType === "CC" &&
            selectedPaymentMethod != null &&
            billingAddresses != null) ||
            (selectedPaymentMethod?.paymentType === "ACH" &&
              selectedPaymentMethod != null)) && (
            <div className="mt-6 mb-20">
              <h2 className="text-[#FFFFFF] text-[20px] leading-6 mb-4 font-poppins font-bold ml-2">
                Review & Pay
              </h2>
              <div className="flex items-center justify-between rounded-lg">
                <div className="w-16 h-16 p-2 rounded-12">
                  <img
                    onClick={isLanding ? handleReviewAndPayClick : () => {}}
                    className="w-full h-full cursor-pointer"
                    src={
                      subscriptionPlanData?.pageData?.subscriptionIcon
                        ? subscriptionPlanData.pageData.subscriptionIcon
                        : ""
                    }
                    alt="Subscription Icon"
                  />
                </div>

                <div className="flex-1 text-left ml-2">
                  <p
                    className="review-name-p"
                    onClick={isLanding ? handleReviewAndPayClick : () => {}} // Use a no-op function if not clickable                    className="text-[#FFFFFF] font-semibold font-poppins text-[14px] leading-4 text-left"
                  >
                    {fintechName}
                  </p>

                  <p
                    onClick={isLanding ? handleReviewAndPayClick : () => {}} // Use a no-op function if not clickable                    className="text-[#FFFFFF] font-semibold font-poppins text-[14px] leading-4 text-left"
                    className="eview-pay-fintech text-light font-poppins text-[12px] font-medium leading-4 mt-1"
                  >
                    {subscriptionPlanData?.pageData?.subscriptionTitle}
                  </p>
                </div>
                <div className="text-right">
                  {filteredPlansData &&
                  filteredPlansData[0]?.inTrial === false &&
                  activePlanData &&
                  previousPlanData ? (
                    <>
                      <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                        $
                        {activePlanData?.recurringAmount &&
                        previousPlanData?.recurringAmount &&
                        activePlanData.recurringAmount >
                          previousPlanData.recurringAmount
                          ? activePlanData.recurringAmount
                          : activePlanData?.recurringAmount || "N/A"}
                      </p>
                      <p className="text-light leading-6 text-[12px] font-poppins">
                        {subscriptionPlanData.pageData?.billingCycle ===
                        "Annually"
                          ? "Year"
                          : "Month"}
                      </p>
                      {activePlanData?.recurringAmount >
                        previousPlanData?.recurringAmount && (
                        <p className="text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                          Upgrade Cost: ${formattedProratedDifference || "N/A"}
                        </p>
                      )}
                    </>
                  ) : (
                    <div className="text-right">
                      <p className="review-recurring-amount text-[#FFFFFF] font-poppins font-semibold text-[14px]">
                        ${subscriptionPlanData.pageData.oneTimeAmount}
                      </p>
                      <p className="subscription-type text-light leading-6 text-[12px] font-poppins">
                        {getPeriodCharge()}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* Approve Payment Button */}

              {fintechName == "PortfolioPilot" ? (
                <div className="mt-3 main-trial-div rounded-lg p-2 flex items-center justify-center bg-[#2C2C2C]">
                  <div className="flex flex-col items-center space-y-2 p-2">
                    <span className="text-[#FFFFFF] inner-trial-div ">
                      {filteredPlansData && filteredPlansData[0]?.inTrial
                        ? "Cancel anytime, no payment due date"
                        : activePlanData && previousPlanData
                          ? activePlanData.recurringAmount >
                            previousPlanData.recurringAmount
                            ? `Your plan has been upgraded. Previous plan: $${previousPlanData.recurringAmount}, New plan: $${activePlanData.recurringAmount}`
                            : activePlanData.recurringAmount <
                                previousPlanData.recurringAmount
                              ? `Your plan has been downgraded. Previous plan: $${previousPlanData.recurringAmount}, New plan: $${activePlanData.recurringAmount}`
                              : "No change in plan."
                          : "Cancel anytime, no payment due date"}
                      <br />
                      <span className="cancel-subscription-pportfoliofont-poppins font-semibold text-light top-2">
                        {/* {filteredPlansData && filteredPlansData[0]?.inTrial
                        ? "Monthly payment starts in 14 days"
                        : "Completed at the End of 30 days/Billing cycle"} */}

                        {filteredPlansData && filteredPlansData[0]?.inTrial
                          ? "Monthly payment starts in 14 days"
                          : activePlanData && previousPlanData
                            ? activePlanData.recurringAmount >
                              previousPlanData.recurringAmount
                              ? `Completed at the End of 30 days/Billing cycle`
                              : activePlanData.recurringAmount <
                                  previousPlanData.recurringAmount
                                ? `Completed at the End of 30 days/Billing cycle`
                                : "Completed at the End of 30 days/Billing cycle"
                            : "Monthly payment starts in 14 days"}
                      </span>
                    </span>
                  </div>
                </div>
              ) : null}

              {getDisclosureText() !== null ? (

<div className="group-82805">
<div className="rounded-rectangle"></div>
  <div className="labelDisclosureTitle">{getDisclosureText()?.title}</div>
  <div className="labelDisclosureDescr">{getDisclosureText()?.descr}</div>

</div>

              ) : null
              }



              <div className="fixed bottom-0 left-0 right-0 p-4">
                <button
                  onClick={() => {
                    
                    if (IsSubscriptionUpgrade()) {
                      // handleUpdate(); // Call your update method here

                      handleUpdate(
                        selectedBillingAddr,
                        selectedPaymentMethod
                      );
                    } else {
                      handleapp(
                        selectedBillingAddr,
                        selectedPaymentMethod
                      );
                    }
                  }}
                  className="w-full bg-[#705AE2] text-white py-4 rounded-lg mt-6 transition duration-300 focus:outline-none focus:ring-2 font-poppins font-bold shadow-md"
                  style={layout?.buttonStyle}
                >
                  {Config.isActiveSubscription
                    ? "UPDATE"
                    : isTrilStart
                      ? "PROCEED WITH TRIAL"
                      : "APPROVE PAYMENT"}
                </button>
              </div>
            </div>
          )}
        {/* Debugging Information */}
      </div>
    </div>
  );
};

export default SubscriptionPayment;
