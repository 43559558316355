import  { useState } from "react";

import "../../assets/css/style.css";

import rate_ex_diss from "../../assets/Images/emotion/extremelly_dissatisfied.svg";
import rate_diss from "../../assets/Images/emotion/dissatisfied.svg";
import rate_neutr from "../../assets/Images/emotion/neutral.svg";
import rate_sat from "../../assets/Images/emotion/satisfied.svg";
import rate__ex_sat from "../../assets/Images/emotion/extremelly_satisfied.svg";

import {useFeedbacks,useFeedbackMutation,useFintechSubscriptions,getCheckOutSessionPages} from '../../services/AsaApiServices'


interface IPropsRateSelector{
    rate:number,
    setRate(val:number):void
  }
  interface IIRateItem{
    rateVal:number,
    text:string,
    imgsrc:string
  
  }
  interface IPropsSubscriptionFeedback{
    onSubmitFeedback():void
  }

const RateSelector=({rate,setRate}:IPropsRateSelector)=>{
    const rateitems:IIRateItem[]=[
     {rateVal:1,text:"1",imgsrc:rate_ex_diss},
     {rateVal:2,text:"2",imgsrc:rate_diss},
     {rateVal:3,text:"3",imgsrc:rate_neutr},
     {rateVal:4,text:"4",imgsrc:rate_sat},
     {rateVal:5,text:"5",imgsrc:rate__ex_sat}
    ];
    
    const renderRateItem=(item:IIRateItem,idx:number,isSelected:boolean)=>{
     return (
       <div key={idx}>
           <img src={item.imgsrc} 
           alt={item.text} 
           className={"mx-2 subscription-rate-icon"+ (isSelected?' subscription-rate-icon-inverted':'')}
           onClick={(e)=>setRate(item.rateVal)}
           />
       </div>
     )
    }
   return(
     <div className="flex flex-row">
         {rateitems.map((item,idx)=>renderRateItem(item,idx,rate===item.rateVal))}
     </div>
   )
 }
 const GuacSubscriptionFeedbackSubmitted=()=>
    <div className="subscription-feedback-submitted-container">
        <div className="subscription-feedback-submitted flex flex-col mt-6 items-center">
          <div className="">
            <p>Thank you!</p> 
            <p></p>
            <p>Your feedback has been recorded.</p>
            
          </div>
        </div>
    </div>
 const SubscriptionFeedback =({onSubmitFeedback}:IPropsSubscriptionFeedback)=>{
   const [rate,setRate]=useState(-1)
   const [feedback,setFeedback]=useState('')
   const isDisabledFeedback= rate <0  || feedback.length===0
   const feedbackMutation=useFeedbackMutation()
   const [isFeedbackSubmitted,setFeedbackSubmitted]=useState(false)
   const handleSubmit=async ()=>{
     if(isDisabledFeedback)
       return
     try{
       await feedbackMutation.mutateAsync({rate:rate,msg:feedback})
     }
     catch(err){
       //to do
       console.log(err)
     }
     setFeedbackSubmitted(true)
     
     setTimeout(() =>  {
        setFeedbackSubmitted(false)
        onSubmitFeedback()
    }, 5000)
    
    
   }
 return(
   <>
 
     <div className="flex flex-col  items-center">
        {!isFeedbackSubmitted &&
            <>
                <div className="flex flex-col subscription-feedback-head mt-10">
                    Rate your experience with Guac.
                </div>
                <div className="flex flex-row mt-4">
                    <div className="flex flex-row mx-2">bad</div>
                    <RateSelector rate={rate} setRate={setRate}></RateSelector>
                    <div className="flex flex-row mx-2">good</div>
                </div>
                <div className="subscription-feedback-text mt-8">
                    <textarea className="subscription-feedback-input" 
                    placeholder="Share your thoughts here..."
                    value={feedback}
                    onChange={(e)=>setFeedback(e.target.value)}
                    ></textarea>
                </div>
                <div className={"mt-4 subscription-btn-submitfeedback" +(isDisabledFeedback?' subscription-btn-submitfeedback-disabled':'')}
                    onClick={()=>handleSubmit()}>
                    Submit Feedback
                </div>
            </>
        }
        {isFeedbackSubmitted && <GuacSubscriptionFeedbackSubmitted/>}
     </div>
   </>
 )
 
 }
 export default SubscriptionFeedback