import React, { useState,useContext } from "react";

import { Config } from "../../Utils/AppConstants";
import outer_eclipse from "../../assets/Images/cancelled_outer.png";
import money_off from "../../assets/Images/money_off.png";
import subcr_info from "../../assets/Images/guac_subcr_info.svg";
import "./ReactivatePlanScreen.css"; // Import your CSS file for animations

import SubscriptionFeedback from "./SubscriptionFeedback"
import {useFeedbacks,useFintechSubscriptions,getCheckOutSessionPages} from "../../services/AsaApiServices"
import { useNavigate } from "react-router-dom";
import { useDispatch  } from "react-redux";
import {
  setSessionData,setSessionIDStore,setAsaConsumerCode,setCOnsumerAdress
} from "../../redux/sessionSlice";
import {useAsaSession,useAsaSessionMutation,useAsaSessionPagesMutation} from '../../services/AsaSessionServices'
import { AsaStateContext } from '../../components/AsaStateProvider'

import SubscriptionManagementBase from "./SubscriptionManagementBase"

const ReactivatePlanScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const {data:session}=useAsaSession()
  const [state, ] = useContext(AsaStateContext)
  const sessionMutation= useAsaSessionPagesMutation()

  const fintechSubscriptions=useFintechSubscriptions()

  const existSubscription=Array.isArray(fintechSubscriptions?.data)?fintechSubscriptions.data[0]:undefined
  const feedbacksquery=useFeedbacks()
  const [isFeedbackOpen,setFeedbackOpen]=useState(false)
  const isAlreadyFeedback=feedbacksquery.isSuccess && feedbacksquery.data && feedbacksquery.data.length>0
 


  const formatDate = (dateString: string | number | Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric", // Correctly typed as 'numeric'
      month: "long", // Correctly typed as 'long'
      day: "numeric", // Correctly typed as 'numeric'
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  const handleReactivate=async ()=>{
    const sessionToUpdate={...session}
    sessionToUpdate.sessionPages=undefined
    const sessionpages=[]
    const {pages:checkoutPages,billingAddresses,subscriptionsPlans}=await getCheckOutSessionPages(state)
    const selectedPlan=subscriptionsPlans?.find(x=>x.subscriptionPlanCode==existSubscription?.subscriptionPlanCode)
    if(selectedPlan){
        const selectPackagepage:SessionPage={
          pageNumber:2,
          pageName: "GOALS PLANS",
          pageTitle: "Select Package",
          pageDescription: "Select Package",
          pageUrl: "/moneypatrol",
          pageStatusMessage: "INCOMPLETE",
          pageData:JSON.stringify(selectedPlan)
        }
        sessionpages.push(selectPackagepage)
    }
    const pages=sessionpages.concat(checkoutPages)
    const response= await sessionMutation.mutateAsync({asaSession:sessionToUpdate,extraPages:pages})
    //for bakward compatibility
    dispatch(setSessionData({data:response}))
    dispatch(setSessionIDStore(state.sessionID));
    dispatch(setAsaConsumerCode(state.asaConsumerCode));
    dispatch(setCOnsumerAdress({data:billingAddresses}));
    Config.token=state.token || ""
    navigate('/portfoliopilot')
  }
  const handleManageTrialClick = () => {};

  return (
    <SubscriptionManagementBase>
        {!isFeedbackOpen &&
          <div className="flex flex-col justify-between">
          <div className="flex-grow">
            <div className=" rounded-3xl shadow-lg p-6 w-[22rem] text-center">
              {/* Icon Section */}
              <div className="flex items-center justify-center">
                <div className="relative p-4 rounded-full">
                  <img
                    src={outer_eclipse} // replace with your outer circle image
                    alt="Outer Circle"
                    className="h-40 w-40" // Adjust size as needed
                  />
                  <img
                    src={money_off} // replace with your credit card image
                    alt="money Card"
                    className="absolute inset-0 h-24 w-24 m-auto p-2" // Center the credit card and add padding
                  />
                </div>
              </div>

          
              <p className="text-[#DF4949] text-center font-poppins text-[14px] font-semibold leading-[20px] tracking[-0.1px]">
                <span className="mr-2">•</span>Cancelled
              </p>

              <h1 className="text-xl text-white font-semibold">
                {existSubscription?.subscriptionTitle}
              </h1>

              {/* Trial end date */}
              <div className="bg-[#2C2C2C] rounded-lg  p-2 relative mt-4">
                <div className="absolute top-2 left-2">
                  {/* Replace the following SVG with your own */}
                  <img src={subcr_info}/>
                  {/*
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 text-white"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01M12 2a10 10 0 100 20 10 10 0 000-20z"
                    />
                  </svg>
                  */}
                </div>

                <div className="overflow-hidden text-center">
                  <p className="trial-end-text">
                    Your free trial will end on
                  </p>
                  <p className="trial-date-text">
                    {existSubscription?.subscriptionEndDate
                      ? formatDate(existSubscription?.subscriptionEndDate)
                      : "N/A"}
                  </p>

                  <p className="trial-message-recativate">
                    After that, you will no longer have access and your
                    payment method on file will not be charged. Please help us
                    improve by answering a quick survey about why you
                    canceled.
                  </p>

                  {!isAlreadyFeedback &&
                    <button
                      className="mt-4 w-[162px] h-[42px] flex-shrink-0 bg-[#4E4E4E] text-white font-poppins text-[11px] font-semibold leading-[20px] tracking[-0.1px] text-center rounded-[10px]"
                      style={{ textTransform: "capitalize" }}
                      onClick={()=>setFeedbackOpen(true)}
                    >
                      Give Feedback
                    </button>
                  }
                </div>
              </div>

              {/* Action Buttons */}
              <div className="mt-6 mb-4">
                <button
                  onClick={handleReactivate}
                  className="reactivate-button"
                >
                  <span className="button-text-style-reactivate">
                    REACTIVATE PLAN
                  </span>
                </button>
              </div>
            </div>
          </div>
        
        </div>


        }
        {isFeedbackOpen && <SubscriptionFeedback onSubmitFeedback={()=>setFeedbackOpen(false)} />}
    </SubscriptionManagementBase>
  
  );
};

export default ReactivatePlanScreen;
