import React,{ useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";


import "./CancelSubscriptionScreen.css"; // Import your CSS file for animations
import outer_eclipse from "../../assets/Images/cancel_trial_eclipse.png";
import credit_card from "../../assets/Images/cancel_close.png";
import { ToastContentProps, toast } from "react-toastify";
import {useSubscriptionCancedlMutation,useFintechLayouts,useFintechSubscriptions} from "../../services/AsaApiServices"

import { useIsFetching,useIsMutating } from "react-query";

import SubscriptionManagementBase from "./SubscriptionManagementBase"

const formatDate = (dateString: string | number | Date) => {
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric", // Correctly typed as 'numeric'
    month: "long", // Correctly typed as 'long'
    day: "numeric", // Correctly typed as 'numeric'
  };
  return new Date(dateString).toLocaleDateString("en-US", options);
};


const CancelSubscription = () => {
  const navigate = useNavigate();


  const fintechSubscriptions=useFintechSubscriptions()
  const fintechLaoyut=useFintechLayouts() //getLayoutFromFintech(fintech);

  const subscriptionCancelMutation= useSubscriptionCancedlMutation()


  const activeSubscription=Array.isArray(fintechSubscriptions?.data)?fintechSubscriptions.data[0]:undefined
 

  const showCustomToast = (
    message:
      | string
      | number
      | boolean
      | React.ReactElement<any, string | React.JSXElementConstructor<any>>
      | Iterable<React.ReactNode>
      | React.ReactPortal
      | ((props: ToastContentProps<unknown>) => React.ReactNode)
      | null
      | undefined,
    isError: boolean = false // Add a parameter to specify if it's an error message
  ) => {
    // Determine the toast type based on whether it's an error or success
    const toastType = isError ? toast.error : toast.success;

    // Show the toast message
    toastType(message, {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      style: {
        backgroundColor: isError ? "#A2252C" : "#4BB543", // Set the background color
        color: "white", // Set the text color
        fontFamily: "Poppins", // Set the font family
      },
      bodyClassName: "poppins-font", // Add a class for additional styling
    });
  };



  const handleCancel = async () => {
    const billingDetailCode = activeSubscription?.billingDetailCode;

    if (!billingDetailCode) {
      console.error("BillingDetailCode is missing");
      return; // Exit if no billing detail code is available
    }

    const requestData = {
      BillingDetailCode: billingDetailCode,
    };

    try {
      await subscriptionCancelMutation.mutateAsync(requestData)
      navigate("/cancelled");
    } catch (error) {
      console.error( error);
      showCustomToast("Failed to cancel. " +error , true);
    }
  };

  return (
    <SubscriptionManagementBase>
                <div className="flex flex-col items-center justify-center flex-grow">
            <div className="cancel-trial-title"> {"Cancel"+(activeSubscription?.inTrial?" Trial":"")}</div>
            <div className="relative p-4 rounded-full">
              <img
                src={outer_eclipse} // replace with your outer circle image
                alt="Outer Circle"
                className="h-40 w-40" // Adjust size as needed
              />
              <img
                src={credit_card} // replace with your credit card image
                alt="Credit Card"
                className="absolute inset-0 h-24 w-24 m-auto p-2" // Center the credit card and add padding
              />
            </div>
            <p className="cancel-text-cancel">
              Are you sure you want to cancel?
            </p>{" "}
            <p className="continue-access-text-cancel">
              You will continue to have access until
            </p>
            <p className="continue-access-text">{activeSubscription?.subscriptionEndDate
                          ? formatDate(activeSubscription?.subscriptionEndDate)
                          : "N/A"}</p>
          </div>
          {/* Buttons */}
          <div className="flex justify-between px-6 py-4 space-x-4">
            <button
              className="manage-trial-btn-cancel"
              style= {fintechLaoyut?.buttonStyle }
              onClick={()=>navigate(-1)}
              
            >
              GO BACK
            </button>
            <button
              className="cancel-trial-btn-txt-trial"
              onClick={handleCancel} // Add the click handler
            >
               {"Cancel"+(activeSubscription?.inTrial?" Trial":"")}
              
            </button>
          </div>
    </SubscriptionManagementBase>

 
  );
};

export default CancelSubscription;
