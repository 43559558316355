import React, { useState, useEffect,  useRef } from "react";
import {IFintechLayout } from "../types/types";
import "../assets/css/style.css";
import "../assets/css/slider.css";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


export interface IPlanSlide {
    icon: string;
    title: string;
    description: string;
    category:string;

    planCode:number;
    buttonText:string;
  }

  interface IPlanSliderProps {
    slides: IPlanSlide[]
    layout:IFintechLayout | null
    onSubscriptionClick:(a: number ) => void
    hideButtonTextUnderContinue:boolean
  }
  
  const PlanSlider: React.FC<IPlanSliderProps> = ({
    slides,
    onSubscriptionClick,
    layout,
    hideButtonTextUnderContinue
  }) => {


    const [currentSlide, setCurrentSlide] = useState<number>(0);
    const [isDragging, setIsDragging] = useState<boolean>(false);
    const sliderRef = useRef<HTMLDivElement | null>(null);
    const startXRef = useRef<number>(0);
    const dragOffsetRef = useRef<number>(0);
    const [dragOffset, setDragOffset] = useState<number>(0);
    const [isShowButtonText,setIisShowButtonText]=useState<boolean>(!hideButtonTextUnderContinue);
    
    const handleStart = (clientX: number) => {
      startXRef.current = clientX;
      dragOffsetRef.current=0
      setIsDragging(true);
    };
  
    const handleMove = (clientX: number) => {
      if (!isDragging) return;
      const diff = startXRef.current - clientX;
      dragOffsetRef.current = diff;
      setDragOffset(diff);
    };
  
    const handleEnd = () => {
      if (!isDragging) return;
      const threshold = (sliderRef.current?.offsetWidth ?? 0) / 3;
     
      if (Math.abs(dragOffsetRef.current) > threshold) {
        if (dragOffsetRef.current > 0 && currentSlide < slides.length - 1) {
          setCurrentSlide((prev) => prev + 1);
        } else if (dragOffsetRef.current < 0 && currentSlide > 0) {
          setCurrentSlide((prev) => prev - 1);
        }
      }

  
      setIsDragging(false);
      setDragOffset(0);
      dragOffsetRef.current=0
    };
  
    const handleDotClick = (index: number) => {
    
      setCurrentSlide(index);

    };
  

  
    useEffect(() => {
      const slider = sliderRef.current;
  
      if (!slider) return;
  
      const touchStart = (e: TouchEvent) => handleStart(e.touches[0].clientX);
      const touchMove = (e: TouchEvent) => handleMove(e.touches[0].clientX);
      const touchEnd = () => handleEnd();
  
      const mouseDown = (e: MouseEvent) => handleStart(e.clientX);
      const mouseMove = (e: MouseEvent) => handleMove(e.clientX);
      const mouseUp = () => handleEnd();
  
      slider.addEventListener("touchstart", touchStart, { passive: true });
      slider.addEventListener("touchmove", touchMove, { passive: true });
      slider.addEventListener("touchend", touchEnd);
      slider.addEventListener("mousedown", mouseDown);
      window.addEventListener("mousemove", mouseMove);
      window.addEventListener("mouseup", mouseUp);
  
      return () => {
        slider.removeEventListener("touchstart", touchStart);
        slider.removeEventListener("touchmove", touchMove);
        slider.removeEventListener("touchend", touchEnd);
        slider.removeEventListener("mousedown", mouseDown);
        window.removeEventListener("mousemove", mouseMove);
        window.removeEventListener("mouseup", mouseUp);
      };
    }, [isDragging, slides.length]);
  
    return (
      <div
        ref={sliderRef}
        className="relative w-full slider bg-[#EBECF0]  overflow-hidden flex flex-col h-auto mt-8"
      >
        <div className="slider-top" style= {layout?.buttonStyle }>
        {slides[currentSlide].category}
        </div>
  
        <div className="w-full slider-box flex-shrink-0">
          <div
            className="flex transition-transform duration-300 ease-out h-full"
            style={{
              transform: `translateX(calc(-${currentSlide * 100}% + ${
                isDragging ? -dragOffset : 0
              }px))`,
              transition: isDragging ? "none" : "transform 300ms ease-out",
            }}
          >
            {slides.map((slide, index) => (
              <div
                key={index}
                className="w-full flex-shrink-0 p-4 flex justify-center items-center __asa_guac_slider"
              >
                        <div className="flex flex-row items-center">
                          <img
                            src={slide.icon}
                            alt={slide.title}

                            className="max-w-full h-full object-contain"
                          />
                        </div>

               
              </div>
            ))}
          </div>
        </div>
  
        <div className="flex flex-col overflow-y-hidden h-auto sm:h-60 slider-box-content justify-between p-4">
          <div className="overflow-y-auto">
            <h3 className=" mb-2 text-black slider-title font-poppins">
              {slides[currentSlide].title}
            </h3>
            <p className="font-poppins">
              {slides[currentSlide].description}
            </p>
          </div>
  
          <div className="flex justify-center space-x-2 my-4">
            {slides.map((_, index) => (
              <div
                key={index}
                onClick={() => handleDotClick(index)}
                className="w-2 h-2 rounded-full cursor-pointer bg-gray-300"
                style=  {index === currentSlide?layout?.buttonStyle:{} }
              />
            ))}
          </div>
  
            <button
              onClick={()=>
              {
                if(!isShowButtonText){
                  setIisShowButtonText(true);
                }else{
                  onSubscriptionClick(slides[currentSlide].planCode)}
                }
              }
              className="w-full  text-white py-3 rounded-lg shadow-md font-poppins font-semibold"
              style= {layout?.buttonStyle }
            >
              {!isShowButtonText && "CONTINUE"}
              {isShowButtonText && slides[currentSlide].buttonText}
            </button>

        </div>
      </div>
    );
  };

  export {PlanSlider}


