import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../assets/css/slider.css";

interface Slide {
  image: string;
  text: string;
}

interface ImageTextSliderProps {
  slides: Slide[];
}

const ImageTextSlider: React.FC<ImageTextSliderProps> = ({ slides }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    customPaging: (i: number) => (
      <button>
        <div className="dot-custom w-2 mt-3 ml-1 justify-center" />
      </button>
    ),
  };

  return (
    <Slider {...settings}>
      {slides.map((slide, index) => (
        <div key={index} className="p-2 h-[210px] relative">
          <img
            src={slide.image}
            alt={slide.text}
            className="mx-auto w-[80px] h-[80px] object-contain"
          />
          <p
            className="text-[18px] h-[80px] p-2 w-full text-gray-900 font-poppins absolute z-50 -bottom-2 sm:-bottom-2 md:-bottom-5 px-4 bg-white text-left font-medium "
            style={{ letterSpacing: "-0.45px" }}
          >
            {slide.text}
          </p>
        </div>
      ))}
    </Slider>
  );
};

export default ImageTextSlider;
